import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ColDef } from 'ag-grid-enterprise';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import useAsync from 'hooks/useAsync';
import { deleteEsignatureApi, getSignatureList } from 'api/admin/signature-api';
import { useAuth } from '@agentnet/auth';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { GetSignature, SignatureGridModel } from './types';
import NoResults from 'ui-kit/components/noResults/NoResults';
import clsx from 'clsx';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { getStateOptions } from 'api/profile/profile-utilities';
import AddSignatureSliderFC from './AddSignatureSlider';
import { eSignatureContext } from '../types';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { DeleteEsignature, KBLinks } from 'features/constants';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import PageHeader from 'ui-kit/components/headers/PageHeader';

const Signature = (): JSX.Element => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      completeGrid: {
        width: '100%',
        height: '100%',
        padding: '0 24px 24px',
      },
      tableDefault: {
        height: `calc(100vh - 220px) !important`,
      },
      paddingX: {
        padding: '0 24px 24px',
      },
      contentClass: {
        margin: 'auto',
      },
      sigRoot: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
        padding: '8px 0',
      },
      sigContainer: {
        backgroundColor: 'white',
        border: '1px solid #E0E0E0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: '10px',
      },
      sigImage: {
        width: '75%',
        height: 'auto',
        borderRadius: '10px',
      },
    }),
  );

  const classes = useStyles();

  const [signatureData, setSignatureData] = useState<any>([]);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const { getAccessToken } = useAuth();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const { addSnackbarMessage } = useSnackBars();
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  let stateOptions = userFirm?.offices ? getStateOptions(userFirm) : [];
  stateOptions = stateOptions.filter((s) => s.value == 'TX');

  const [openDrawer, setOpenDrawer] = useState(false);
  const [signatureContext, setSignatureContext] = useState<eSignatureContext>();
  const [isDeleteESignature, setisDeleteESignature] = useState<boolean>(false);
  const [signaturedata, setsignaturedata] = useState<eSignatureContext>();

  const addEditButtonDisabled = () => {
    if (profile) {
      if (profile.roleTypeId != 100 && stateOptions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getSignatureData = async (): Promise<GetSignature[]> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    return await getSignatureList(userFirm?.firmId, token);
  };

  const {
    execute: executeSignatureList,
    value: getSignatureResults,
    status: getSignatureStatus,
    errors: getSignatureErrors,
  } = useAsync<any>(getSignatureData, false);

  const deleteSignatureData = async (): Promise<eSignatureContext> => {
    const token = await getAccessToken();
    setSearchFlag(true);
    return await deleteEsignatureApi(signaturedata, token);
  };

  const {
    execute: deleteESignature,
    value: deleteSignatureResults,
    status: deleteSignatureStatus,
    errors: deleteSignatureErrors,
  } = useAsync<any>(deleteSignatureData, false);

  useEffect(() => {
    setDisplayError(getSignatureStatus === 'error');
  }, [getSignatureStatus]);

  useEffect(() => {
    const errorMessages = getSignatureErrors?.length ? getSignatureErrors : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addSnackbarMessage({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (deleteSignatureResults) {
      addSnackbarMessage({
        message: 'eSignature Deleted Successfully',
        type: 'success',
      });
      setSearchFlag(false);
      setSaveSuccess(true);
    }
  }, [deleteSignatureResults]);

  useEffect(() => {
    const errorMessages = deleteSignatureErrors?.length ? deleteSignatureErrors : [];
    setErrMsgs(errorMessages);
    setSearchFlag(false);
  }, [displayError]);

  useEffect(() => {
    setDisplayError(deleteSignatureStatus === 'error');
    setSearchFlag(false);
  }, [deleteSignatureStatus]);

  useEffect(() => {
    if (getSignatureResults) {
      const signatureData: SignatureGridModel[] = getSignatureResults.map((signature: any) => {
        return {
          signatoryName: signature.FullName,
          approvedStates: signature.ApprovedStates,
          eSignature: signature.ESignature,
          firmId: signature.FirmId,
          signatoryId: signature.SignatoryId,
          fullName: signature.FullName,
          isESignatureEnable: signature.IsESignatureEnable,
          firmESignatureId: signature.FirmESignatureId,
        };
      });
      setSignatureData(signatureData);
      setSearchFlag(false);
    }
  }, [getSignatureResults]);

  useEffect(() => {
    // fetch signature data
    executeSignatureList().then();
  }, []);

  const handeleDeleteESignature = (data: any) => {
    setsignaturedata(data);
    setisDeleteESignature(true);
  };

  const cancelDeleteESignature = () => {
    setisDeleteESignature(false);
  };

  const confirmDeleteESignature = () => {
    setisDeleteESignature(false);
    deleteESignature().then();
  };
  const signatureCellRenderer = (params: any) => {
    // Assuming params.value contains the binary data for the signature
    if (!params.value) return ''; // Return empty if no data

    // Convert binary data to base64 for image display (if applicable)
    const signatureImageSrc = `data:image/jpeg;base64,${params.value}`;

    return (
      <div className={classes.sigRoot}>
        <div className={classes.sigContainer}>
          <img src={signatureImageSrc} className={classes.sigImage} />
        </div>
      </div>
    );
  };

  const eSignatureColumnsConfig: ColDef[] = [
    {
      headerName: 'Signatory Name',
      field: 'signatoryName',
      tooltipField: 'signatoryName',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      headerName: 'Approved States',
      field: 'approvedStates',
      filter: 'agTextColumnFilter',
      autoHeight: true,
      cellStyle: { wordWrap: 'break-word', lineHeight: '1.5', padding: '10px 17px' },
      cellRenderer: (params: any) => {
        if (params.value) {
          return params.value
            .split(',')
            .map((state: any) => state.trim())
            .join(', ');
        }
        return '';
      },
    },
    {
      headerName: 'e-Signatures',
      field: 'eSignature',
      autoHeight: true,
      cellRenderer: signatureCellRenderer,
      filter: false,
    },
    {
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
              {params.data.eSignature ? (
                <div>
                  <AgentNetButton
                    onClick={() => handleAddSignature(params?.data)}
                    style={{ margin: '0', padding: '4px 8px' }}
                    variant="outlined"
                    color="primary"
                    data-qa="EditSignature"
                    disabled={addEditButtonDisabled()}
                  >
                    Edit
                  </AgentNetButton>
                  <AgentNetButton
                    onClick={() => handeleDeleteESignature(params?.data)}
                    variant="text"
                    danger
                    data-qa="DeleteSignature"
                  >
                    Delete
                  </AgentNetButton>
                </div>
              ) : (
                <div>
                  <AgentNetButton
                    color="primary"
                    size="small"
                    onClick={() => handleAddSignature(params?.data)}
                    variant="contained"
                    data-qa="AddSignature"
                    disabled={addEditButtonDisabled()}
                  >
                    Add
                  </AgentNetButton>
                </div>
              )}
            </div>
          );
        }
      },
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      cellClass: 'left-align-column',
      headerClass: 'left-align-header',
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: false,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      cellStyle: { whiteSpace: 'normal' },
    };
  }, []);

  const CustomOverlayNoRows = () => {
    if (getSignatureStatus == 'pending' && (!signatureData || signatureData.length === 0)) {
      return (
        <div className={classes.contentClass}>
          <NoResults
            showButton={false}
            type="results"
            titleText="No Results Found"
            pText="Try adjusting your filters to find what you’re looking for."
          />
        </div>
      );
    }
  };

  // Handle Add Signature Slider
  const handleAddSignature = (sigContext: any) => {
    setOpenDrawer(true);
    setSignatureContext(sigContext);
  };

  useEffect(() => {
    if (saveSuccess) {
      executeSignatureList().then();
      setSaveSuccess(false);
    }
  }, [saveSuccess]);

  return (
    <ContentContainer fullWidth>
      <div className="paper-layout contained">
        <LoadingSpinner status={searchFlag ? 'pending' : 'success'} variant="linear" />
      </div>
      <PageHeader
        title="E-Signatures"
        subtitle="Manage electronic signatures (eSignatures) for Policy Signatories.  eSignatures are available for selection on some policy types including the Short Form Residential Loan policy. To add or remove Policy Signatory, contact your First American Account Executive."
        menuItems={[
          {
            label: 'Knowledge Base',
            link: KBLinks.eSignatures,
          },
        ]}
      />
      {getSignatureStatus == 'success' && (
        <>
          <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
            <DataTable
              rowData={signatureData}
              columnDefs={eSignatureColumnsConfig}
              defaultColDef={defaultColDef}
              components={{
                customOverlayNoRows: CustomOverlayNoRows,
              }}
            />
          </div>
          <div>
            <AddSignatureSliderFC
              isDrawerOpen={openDrawer}
              closeDrawer={() => setOpenDrawer(false)}
              sliderSignatureCtx={signatureContext}
              saveSuccess={(saveSuccess: boolean) => setSaveSuccess(saveSuccess)}
            />
          </div>
          <div>
            <AgentNetConfirmationDialog
              qaAttrPrefix="ConfirmationEndorsementDelete"
              onConfirm={confirmDeleteESignature}
              open={isDeleteESignature}
              onDismissAction={cancelDeleteESignature}
              dialogTitle="Delete eSignature"
              dialogBtnContent="Yes, Delete"
              dialogText={DeleteEsignature.DELETE_ESIG_CONFIRM_MSG}
            />
          </div>
        </>
      )}
    </ContentContainer>
  );
};

export default Signature;
