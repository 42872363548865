import React, { useContext, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useAuth } from '@agentnet/auth';
import { RouteType } from './Router';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GlobalHeader from '../header/header';
import { useViewState } from 'hooks/ViewStateContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import Footer from 'core/footer/footer';
import ProductFruitsFullStory from 'core/layout-core/core-content/ProductFruitsFullStory';
import LeftNav from 'core/navbar/LeftNav';
import {
  AccountingLinks,
  AdminLinks,
  DashboardLinks,
  FilesLinks,
  ReportsLinks,
  ServicesLinks,
} from 'core/navbar/LeftNavLinks';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import IdlePrompter from 'core/IdlePrompter';
import HelpLayout from 'features/help/components/Main/HelpLayout/HelpLayout';
import { ContentfulErrorProvider } from 'features/help/hooks/ContentfulErrorContext';
import AppSwitcherLinks from 'core/navbar/AppSwitcherLinks';
import NavFileInfo from 'features/files/FileSnapshotLeftNav';
import { NavLinkType } from '../navbar/NavLinkType';
import { useNavPermissions } from 'hooks/useNavPermissions';

// A wrapper for <Route> that handles "sub"-routes by passing them as
// `routes` prop to the component it renders.

const RouteWithSubRoutes = (route: RouteType & { isNested?: boolean }): JSX.Element => {
  const auth = useAuth();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      routeRoot: {
        display: 'flex',
        flex: `1 0 auto`,
        backgroundColor: !route.isNested ? '#FAFAFA' : 'transparent',
        width: '100%',
        height: 'auto',
      },
      loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width: '100vw', // Full viewport width
        position: 'fixed', // Optional: Use if you want the loader to be fixed on the screen
      },
    }),
  );
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (!route.isPublic && !auth.isAuthenticated()) {
      auth.login().then();
    }
  }, []);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  const { userFirm, setNavigated, profileStatus, profile } = profileCtx;

  const [appSwitcherActive, setAppSwitcherActive] = useState(false);
  const { viewState, setViewState, menuCollapsed, setMenuCollapse, navState, setNavState } = useViewState();
  const [drawerWidth, setDrawerWidth] = useState(menuCollapsed ? 72 : 311);
  const { hasPermission, prismUserProfile, fileData } = useNavPermissions();

  // TODO: Fix the router. This is a hack because the router is not working appropriately.
  // Helper function to merge nav states and remove non-existing links
  const mergeNavStates = (existingNavState: NavLinkType[], newNavState: NavLinkType[]) => {
    const mergedState = existingNavState.filter((existingLink) =>
      newNavState.some((newLink) => newLink.path === existingLink.path),
    );

    newNavState.forEach((newLink) => {
      const existingLinkIndex = mergedState.findIndex((link) => link.path === newLink.path);
      if (existingLinkIndex !== -1) {
        // Only update the expanded property if necessary
        mergedState[existingLinkIndex] = {
          ...mergedState[existingLinkIndex],
          expanded: mergedState[existingLinkIndex].expanded,
        };
      } else {
        mergedState.push(newLink);
      }
    });

    return mergedState;
  };

  // TODO: Unwind all of this once the router is fixed.
  useEffect(() => {
    if (!route.path.includes('/files')) {
      window.sessionStorage.removeItem('properties');
    }
    const pathname = route.location.pathname;
    let newNavState: NavLinkType[] = [];

    // Logic to determine the nav state based on the pathname
    switch (true) {
      case pathname.startsWith('/files'):
        newNavState = FilesLinks;
        break;
      case pathname.startsWith('/accounting') || pathname.startsWith('/invoices') || pathname.startsWith('/statements'):
        newNavState = AccountingLinks;
        break;
      case pathname.startsWith('/reporting'):
        newNavState = ReportsLinks;
        break;
      case pathname.startsWith('/services'):
        newNavState = ServicesLinks;
        break;
      case pathname.startsWith('/dashboards'):
        newNavState = DashboardLinks;
        break;
      case pathname.startsWith('/admin'):
        newNavState = AdminLinks;
        break;
      default:
        newNavState = FilesLinks;
    }
    setNavState(mergeNavStates(navState, newNavState));
  }, [route.path]);

  const onViewSwitchAdmin = () => {
    setViewState('admin/user');
    history.push('/admin/user/profile');
  };

  const onViewSwitchPortal = () => {
    setViewState('portal');
    history.push('/files');
  };

  // TODO: Permissions Check for each route
  // const checkPermission = () => {
  //   if (route.requiredPermission) {
  //     if (Array.isArray(route.requiredPermission)) {
  //       // check if user has at least one of the required permissions
  //       return route.requiredPermission.some(
  //         (perm) => perm in hasPermission && hasPermission[perm as keyof typeof hasPermission],
  //       );
  //     } else {
  //       // single permission check
  //       return (
  //         route.requiredPermission in hasPermission &&
  //         hasPermission[route.requiredPermission as keyof typeof hasPermission]
  //       );
  //     }
  //   }
  //   return true;
  // };

  return (
    <>
      {(auth.isAuthenticated() || route.isPublic) && (
        <>
          <ProductFruitsFullStory />
          <IdlePrompter />
          <Route
            path={route.path}
            render={(props) => {
              // pass the sub-routes down to keep nesting4
              if (route.layout === 'AuthLayout') {
                // if (!checkPermission()) {
                //   return <Redirect to="/" />;
                // }
                if (hasPermission.orderManagement && route.location.pathname === '/order-management') {
                  hasPermission.hasFirmId = false;
                }
                return (
                  <>
                    <div className={clsx(classes.routeRoot, 'routeRoot')}>
                      <GlobalHeader
                        onAppSwitcherClick={(e) => {
                          setAppSwitcherActive(!appSwitcherActive);
                        }}
                        onViewSwitch={onViewSwitchAdmin}
                        onPortalSwitch={onViewSwitchPortal}
                        currentView={viewState}
                        hasPermission={hasPermission}
                        hideNav={hasPermission.hasFirmId ? false : true}
                      />
                      {route.location.pathname !== '/files' &&
                        route.location.pathname !== '/searchFirms' &&
                        hasPermission.hasFirmId && (
                          <LeftNav
                            menuCollapsed={menuCollapsed}
                            setMenuCollapse={setMenuCollapse}
                            navState={navState}
                            setNavState={setNavState}
                            pathParams={route}
                            hasPermission={hasPermission}
                            userFirm={userFirm}
                            prismUserProfile={prismUserProfile}
                            fileData={fileData}
                            {...props}
                          >
                            {route.location.pathname.startsWith('/files') && <NavFileInfo />}
                          </LeftNav>
                        )}

                      {!userFirm?.firmId && profileStatus !== 'success' ? (
                        <div className={classes.loader}>
                          <LoadingSpinner variant="circle" status={profileStatus} />
                        </div>
                      ) : (
                        <route.component
                          key={userFirm?.firmId}
                          {...props}
                          routes={route.routes}
                          isNested={route.isNested}
                        />
                      )}

                      <AppSwitcherLinks
                        visible={appSwitcherActive}
                        drawerWidth={drawerWidth}
                        setVisible={setAppSwitcherActive}
                      />
                    </div>
                  </>
                );
              } else if (route.layout === 'PublicLayout') {
                return (
                  <>
                    <GlobalHeader currentView={'public'} hideNav={true} />
                    <div className={clsx(classes.routeRoot, 'routeRoot')}>
                      <route.component {...props} routes={route.routes} isNested={route.isNested} />
                    </div>
                    <Footer />
                  </>
                );
              } else if (route.layout === 'HelpLayout') {
                return (
                  <ContentfulErrorProvider>
                    <HelpLayout>
                      <div className={clsx(classes.routeRoot, 'routeRoot')}>
                        <route.component {...props} routes={route.routes} isNested={route.isNested} />
                      </div>
                    </HelpLayout>
                  </ContentfulErrorProvider>
                );
              } else {
                return (
                  <div className={clsx(classes.routeRoot, 'routeRoot')}>
                    <route.component {...props} routes={route.routes} isNested={route.isNested} />
                  </div>
                );
              }
            }}
          />
        </>
      )}
    </>
  );
};
export default RouteWithSubRoutes;
