import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Popper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  AppsOutlined,
  Clear,
  HelpOutlineOutlined,
  InfoOutlined,
  MessageOutlined,
  NotificationsOutlined,
  SwapHoriz,
  ExitToAppOutlined,
} from '@material-ui/icons';
import clsx from 'clsx';
import Logo from 'ui-kit/icons/AgentNetLogo';
import LogoutOutlined from 'ui-kit/icons/LogoutOutlined';
import { useAuth } from '@agentnet/auth';
import { ProfileContext, ProfileContextInterface } from '../../hooks/ProfileContext';
import { FileDataContext, FileDataContextInterface } from '../../hooks/FileDataContext';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Firm } from 'api/profile/types';
import { getDefaultFirm } from 'api/profile/profile-utilities';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AboutAppDialog from 'ui-kit/components/modal/AboutAppDialog';
import ContactUsDialog from 'ui-kit/components/modal/ContactUsDialog';
import { isProductFruitsReady } from 'react-product-fruits';
import TopNav from 'core/navbar/TopNav';
import { FileCreateProvider } from 'hooks/FileCreateContext';
import { OrderCreateProvider } from 'hooks/OrderCreateContext';
import FileCreateContainer from 'features/files/file-create/FileCreateContainer';
import OrderCreateContainer from 'features/orderManagement/order-create/OrderCreateContainer';
import { getFirmDetails } from 'api/onBehalfOf/api';
import useAsync from 'hooks/useAsync';
import { useUser } from 'hooks/SignUpContext';
import { getAnteUrl, isEnableAllFeatures } from 'api/host-by-env';
import { DefaultNewFile } from 'models/file-model';
import { HashUtils } from '@agentnet/utils';
import { clearUserCache } from 'api/file/file-api';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface GlobalHeaderProps {
  onAppSwitcherClick?: (e: any) => void;
  onViewSwitch?: () => void;
  currentView?: string;
  onPortalSwitch?: () => void;
  hideNav?: boolean;
  hasPermission?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      flexShrink: 0,
      position: 'fixed',
      width: '100%',
      zIndex: 15,
    },
    logoWrap: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    feedbackBtn: {
      marginLeft: theme.spacing(4),
    },
    signInBtn: {
      marginLeft: theme.spacing(4),
    },
    appSwitcherIconBtn: {
      marginRight: theme.spacing(3),
      padding: 0,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      height: 64,
      width: 64,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&>span': {
        pointerEvents: 'none',
      },
    },
    appBar: {
      backgroundColor: theme.palette.brand.legacy,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `${theme.palette.common.white}4C`,
      transition: '.3s all cubic-bezier(0.420, 0.000, 0.580, 1.000)',
      '&:hover': {
        backgroundColor: `${theme.palette.common.white}3F`,
      },
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      right: 0,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 0, 1, 2),
      // vertical padding + font size from searchIcon
      paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      color: theme.palette.primary.contrastText,
      width: '100%',
      minHeight: '30px',
      [theme.breakpoints.up('md')]: {
        width: '30vh',
      },
    },
    sectionDesktop: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    toolBar: {
      justifyContent: 'space-between',
      minHeight: '64px',
      padding: '0 24px 0 0',
    },
    accountButton: {
      alignItems: 'center',
      padding: 0,
      height: '64px',
      display: 'flex',
      minWidth: 'auto',
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
      },
    },
    accountButtonTxt: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '40px',
      border: `1px solid rgba(255, 255, 255, 0.23)`,
      textAlign: 'center',
      '& span': {
        marginTop: '4px',
      },
    },
    accountMenuWrap: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: `0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)`,
    },
    accountMenuList: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    accountMenu: {
      width: '300px',
    },
    accountMenuDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    disableHover: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    popper: {
      backgroundColor: '#FFF',
      borderRadius: '6px',
      zIndex: 20,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: `0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)`,
      width: '300px',
    },
    popperContent: {
      width: 300,
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '6px 16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
    popperlistbox: {
      padding: '0 12px 16px',
    },
    inputBaseWrap: {
      padding: '16px 12px 12px',
    },
    inputBase: {
      width: '100%',
      '& input': {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14,
      },
    },
    firmSwitchWrap: {
      '&:hover': {
        background: 'transparent',
      },
    },
    firmName: {
      fontSize: pxToRem(16),
      fontWeight: 500,
    },
    firmSwitch: {
      margin: theme.spacing(0, 1),
    },
    menuIconBtn: {
      marginLeft: theme.spacing(1),
    },
    divider: {
      borderBottom: '1px solid #c4c4c4',
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    adminText: {
      marginLeft: theme.spacing(1.5),
      position: 'relative',
      top: 3,
      '& svg': {
        transform: 'none',
      },
    },
    createNewFileBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const GlobalHeader = (props: GlobalHeaderProps): JSX.Element => {
  const { signOut, getAccessToken, account, logout } = useAuth();
  const { onViewSwitch, currentView, onAppSwitcherClick, hideNav, hasPermission } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, setUserFirm, profile, latestProfile, isAdmin, pfNotifications, setPfNotifications, reloadProfile } =
    profileCtx;
  const params: { firmId?: string; action?: string } = useParams();
  const { setUserId, setPreviousPage, previousPage, setLoginUserId } = useUser();

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const {
    setOpenSaveModal,
    isFormDirty,
    setLogoutAttempted,
    firmAnchorEl,
    setFirmAnchorEl,
    setTabValue,
    setIsCreateDrawerClicked,
    openCreateDrawer,
    setOpenCreateDrawer,
    setFileData,
  } = fileDataCtx;

  const isMenuOpen = Boolean(anchorEl);
  const isFirmMenuOpen = Boolean(firmAnchorEl);
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const defaultFirm = getDefaultFirm(profile?.firms);
  const [firm, setFirm] = React.useState<{ name: any; value: any }>(
    userFirm?.firmId
      ? { name: userFirm.name, value: userFirm.firmId }
      : { name: defaultFirm?.name ?? '', value: defaultFirm?.firmId ?? '' },
  );

  const { isStaffAdmin, isFSC } = useMemo(() => {
    //need to change it to check the
    let isStaffAdminUser = false;
    let isFSCUser = false;
    if (profile) {
      setIsProfileLoading(false);
      isStaffAdminUser = profile?.roleId === 13;
      isFSCUser = profile?.roleId === 14;
    }
    return {
      isStaffAdmin: isStaffAdminUser,
      isFSC: isFSCUser,
    };
  }, [profile]);

  useEffect(() => {
    if (profile) {
      if (
        profile?.roleTypeId === 100 &&
        profile?.eulaAcceptedDate == null &&
        !(previousPage && previousPage == '/authorization/eula')
      ) {
        setUserId(profile?.userId?.toString() ?? null);
        setLoginUserId(profile?.login?.toString() ?? null);
        setPreviousPage('/files');
        history.push('/authorization/eula');
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile && previousPage && previousPage == '/authorization/eula') {
      reloadProfile && reloadProfile();
      setPreviousPage(null);
    }
  }, []);

  const previousFirm = React.useRef(firm).current;
  const history = useHistory();
  const location = useLocation();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHelpClick = () => {
    window.open('/help', '_blank');
    handleMenuClose();
  };

  const handleSwitchToClassicClick = () => {
    let anteUrl = `${getAnteUrl()}?t=AN2`;

    if (userFirm?.firmId) {
      const encodedFirmId = HashUtils.encode(parseInt(userFirm.firmId));
      anteUrl += `&f=${encodedFirmId}`;
    }

    window.open(anteUrl, '_blank');
    handleMenuClose();
  };

  const handleLogout = async () => {
    // If the Edit File form has unsaved changes, open the modal
    if (isFormDirty) {
      setOpenSaveModal && setOpenSaveModal(true);
      setLogoutAttempted && setLogoutAttempted(true);
      handleMenuClose();
    }
    // otherwise, just do the action
    else {
      if (profile?.login) {
        try {
          const token = await getAccessToken();
          await clearUserCache(profile.login, token);
          sessionStorage.clear();
          localStorage.clear();
        } catch (error) {
          console.error('Error clearing user cache:', error);
        }
      }
      await signOut();
    }
  };

  let name = latestProfile?.FirstName ? latestProfile?.FirstName + ' ' : '';
  name += latestProfile?.LastName ? latestProfile?.LastName : '';

  const company = userFirm ? userFirm.name : '';

  const email = latestProfile?.EmailAddress ? latestProfile?.EmailAddress : '';

  function nameTag() {
    const fname = latestProfile?.FirstName ? latestProfile.FirstName[0].toUpperCase() : '';
    const lname = latestProfile?.LastName ? latestProfile.LastName[0].toUpperCase() : '';
    return `${fname}${lname}`;
  }

  const firmOptions = useMemo(
    () =>
      profile?.firms
        ?.map((firmOption: Firm) => {
          return {
            name:
              firmOption.isDefault && profile?.firms?.length && profile?.firms?.length > 1
                ? `${firmOption.name} (Default)`
                : `${firmOption.name}${firmOption.status === 'Inactive' ? ' (Inactive)' : ''}`,
            value: firmOption.firmId,
          };
        })
        .sort((firstFirm: any, secondFirm: any) => firstFirm.name.localeCompare(secondFirm.name)) ?? [],
    [profile?.firms],
  );

  useEffect(() => {
    setFirmAnchorEl(null);
  }, [location.pathname]);

  function outsideClickPopper(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFirmAnchorEl(null);
          if (firm.value === '') {
            setFirm(previousFirm);
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const popperRef = React.useRef(null);
  outsideClickPopper(popperRef);

  const [aboutModal, setAboutModal] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);

  useEffect(() => {
    window.productFruitsReady = () => {
      window.productFruits?.api?.announcementsV2.listen('newsfeed-unread-count-changed', (data: any) => {
        setPfNotifications && setPfNotifications(data.count);
      });
      attachNewsFeed();
    };
    attachNewsFeed();
  });

  const attachNewsFeed = () => {
    if (isProductFruitsReady()) {
      const customLauncher = document.getElementById('notificationIcon');
      if (customLauncher) {
        window.productFruits?.api?.announcementsV2.attachNewsWidgetToElement(customLauncher);
      }
    }
  };

  const handleFirmChange = (newValue: any, logout = false, firmDetailsResults?: any) => {
    let matchedFirmData;

    if (firmDetailsResults?.firmId === newValue && (profile?.roleId === 3 || profile?.roleId === 5)) {
      matchedFirmData = firmDetailsResults;
    } else {
      matchedFirmData = profile?.firms?.find((firm: Firm) => String(firm.firmId) === String(newValue));
    }
    if (matchedFirmData) {
      setFirm({ name: matchedFirmData.name, value: matchedFirmData.firmId });
      window.localStorage.setItem('userFirm', JSON.stringify(matchedFirmData));
      setUserFirm && setUserFirm(matchedFirmData);
      setFirmAnchorEl(null);
      setTabValue && setTabValue('0');
      if (location.pathname.indexOf('/files') > -1) history.push('/files');
      if (location.pathname.includes('/invoice-summary') || params?.action === 'pay')
        history.push('/accounting/remittance/pay');
      if (location.pathname.includes('/accounting/service-order-invoices/view-invoices')) {
        history.push('/accounting/service-order-invoices/view-invoices');
      }
    } else if (logout) {
      signOut().then();
      sessionStorage.clear();
      localStorage.clear();
    }
  };

  const getFirmDetail = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getFirmDetails(token, params?.firmId ?? '');
  };

  const {
    execute: executeFirmDetails,
    status: executeFirmDetailsStatus,
    value: firmDetailsResults,
  } = useAsync<any>(getFirmDetail, false);

  useEffect(() => {
    if (params?.firmId && params?.firmId !== 'invoice-summary') {
      if (profile?.roleId === 3 || profile?.roleId === 5) {
        executeFirmDetails().then();
      } else if (profile?.firms) {
        handleFirmChange(params?.firmId, true);
      }
    }
  }, [params?.firmId, profile?.firms]);

  useEffect(() => {
    if (firmDetailsResults) {
      handleFirmChange(params?.firmId, true, firmDetailsResults);
    }
  }, [firmDetailsResults]);

  useEffect(() => {
    if (executeFirmDetailsStatus === 'error') {
      handleFirmChange('', true);
    }
  }, [executeFirmDetailsStatus]);

  const activeTab = useMemo(() => {
    let tabIndex = 0;
    const path = location.pathname;
    if (path.startsWith('/files')) return 0;
    if (path.startsWith('/searchFirms')) return 0;
    if (path.startsWith('/order-management')) return 1;
    if (path.startsWith('/accounting') || path.startsWith('/statements')) tabIndex = 1;
    if (path.startsWith('/services')) tabIndex = 2;
    if (path.startsWith('/dashboards')) tabIndex = 3;
    if (path.startsWith('/reporting')) tabIndex = 4;
    if (path.startsWith('/admin')) tabIndex = 5;
    if (isFSC) tabIndex++;
    return tabIndex;
  }, [location.pathname]);

  const isDashboardEnabled = isEnableAllFeatures();

  const topNavTabs = [
    ...(hasPermission?.filesList ? [{ label: 'Files', testId: 'TopNavFiles' }] : []),
    { label: 'Accounting', testId: 'TopNavAccounting' },
    { label: 'Services', testId: 'TopNavServices' },
    ...(isDashboardEnabled ? [{ label: 'Dashboards', testId: 'TopNavDashboards' }] : []),
    { label: 'Reports', testId: 'TopNavReports' },
    { label: 'Admin', testId: 'TopNavAdmin' },
  ];

  const topOrderManagementNavTabs = [
    { label: 'Files', testId: 'TopNavFiles' },
    { label: 'Order Management', testId: 'TopNavOrderManagement' },
    ...(hasPermission?.hasFirmId
      ? [
          { label: 'Accounting', testId: 'TopNavAccounting' },
          { label: 'Services', testId: 'TopNavServices' },
          ...(isDashboardEnabled ? [{ label: 'Dashboards', testId: 'TopNavDashboards' }] : []),
          { label: 'Reports', testId: 'TopNavReports' },
          { label: 'Admin', testId: 'TopNavAdmin' },
        ]
      : []),
  ];

  const handleTabChange = (newValue: number) => {
    setFileData && setFileData(DefaultNewFile);
    switch (newValue) {
      case 0:
        if (hasPermission?.orderManagement) {
          history.push('/searchFirms');
        } else if (hasPermission?.filesList) {
          history.push('/files');
        } else {
          if (hasPermission?.backtitle) {
            history.push('/services/back-title-search');
          } else if (hasPermission?.canRunPolicyUpload) {
            history.push('/services/policy-upload/upload');
          } else {
            history.push('/services/sdn-search');
          }
        }
        break;
      case 1:
        if (hasPermission?.orderManagement) {
          history.push('/order-management');
        } else if (hasPermission?.remit) {
          history.push('/accounting/remittance/report');
        } else if (hasPermission?.pay) {
          history.push('/accounting/remittance/pay');
        } else {
          history.push('/accounting/service-order-invoices/view-invoices');
        }
        break;
      case 2:
        if (hasPermission?.orderManagement) {
          history.push('/accounting/service-order-invoices/view-invoices');
        } else if (hasPermission?.backtitle) {
          history.push('/services/back-title-search');
        } else if (hasPermission?.canRunPolicyUpload) {
          history.push('/services/policy-upload/upload');
        } else {
          history.push('/services/sdn-search');
        }
        break;
      case 3:
        if (isDashboardEnabled) {
          history.push('/dashboards/service-orders');
        } else {
          if (hasPermission?.orderManagement) {
            history.push('/services/sdn-search');
          } else {
            history.push('/reporting');
          }
        }
        break;
      case 4:
        if (isDashboardEnabled) {
          if (hasPermission?.orderManagement) {
            history.push('/services/sdn-search');
          } else {
            history.push('/reporting');
          }
        } else {
          if (hasPermission?.orderManagement) {
            history.push('/reporting');
          } else {
            history.push('/admin/user/profile');
          }
        }
        break;
      case 5:
        if (hasPermission?.orderManagement) {
          history.push('/reporting');
        } else {
          history.push('/admin/user/profile');
        }
        break;
      case 6:
        history.push('/admin/user/profile');
        break;
    }
  };

  const noBehalfOfUser = profile?.roleId === 2 || profile?.roleId === 13;
  const behalfOfUser = profile?.roleId === 3 || profile?.roleId === 5;

  const [openCreateFileDrawer, setOpenCreateFileDrawer] = React.useState<boolean>(false);

  useEffect(() => {
    if (openCreateDrawer) {
      // based on file context open the create drawer
      setOpenCreateFileDrawer(true);
      setOpenCreateDrawer?.(false);
    }
  }, [openCreateDrawer]);

  return (
    <>
      {location.pathname.includes('/Authentication/ForgotPassword') ||
      location.pathname.includes('/Authentication/ForgotUserID') ? null : (
        <div className={classes.headerWrapper}>
          <AppBar elevation={0} position="static" className={classes.appBar}>
            <Toolbar disableGutters className={classes.toolBar}>
              <div className={classes.logoWrap}>
                <IconButton
                  className={classes.appSwitcherIconBtn}
                  disableFocusRipple
                  disableTouchRipple
                  onClick={onAppSwitcherClick && onAppSwitcherClick}
                  data-qa="appswitcher-button"
                >
                  <AppsOutlined fontSize="large" htmlColor="#FFF" />
                </IconButton>
                <Logo link={{ to: '/' }} />
              </div>
              <div className={classes.sectionDesktop}>
                {currentView === 'public' && (
                  <>
                    <AgentNetButton
                      className="white-text"
                      onClick={() => {
                        window.location.href = '#';
                      }}
                    >
                      Integration Info
                    </AgentNetButton>
                    <AgentNetButton
                      className="white-text"
                      onClick={() => {
                        window.location.href = '/contact-us';
                      }}
                    >
                      Contact Us
                    </AgentNetButton>
                    <AgentNetButton
                      className={classes.signInBtn}
                      size="medium"
                      variant="contained"
                      onClick={() => {
                        logout().then();
                      }}
                    >
                      Sign In
                    </AgentNetButton>
                  </>
                )}
                {currentView !== 'public' ? (
                  <>
                    <div className="custom-pf" id="notificationIcon">
                      <NotificationsOutlined fontSize="large" />
                      {pfNotifications && pfNotifications > 0 ? <span>{pfNotifications}</span> : null}
                    </div>
                    {userFirm?.firmId ? (
                      <IconButton
                        aria-label="Switch Firms"
                        color="inherit"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        onClick={(e: any) => {
                          if (
                            profile?.roleId === 2 ||
                            profile?.roleId === 3 ||
                            profile?.roleId === 5 ||
                            profile?.roleId === 13
                          ) {
                            setTabValue && setTabValue('0');
                            history.push('/searchFirms');
                          }
                          if (profile?.roleId === 14) {
                            setTabValue && setTabValue('0');
                            history.push('/order-management');
                          }
                          if (isFirmMenuOpen || !(profile?.firms?.length && profile?.firms?.length > 1)) {
                            setFirmAnchorEl(null);
                          } else {
                            setFirmAnchorEl(e.currentTarget);
                            setFirm({ name: userFirm?.name, value: userFirm?.firmId });
                          }
                        }}
                        className={classes.firmSwitchWrap}
                      >
                        <span className={classes.firmName} data-qa="HeaderFirmName">
                          {company}
                        </span>
                        {((profile?.firms?.length && profile?.firms?.length > 1) ||
                          profile?.roleId === 2 ||
                          profile?.roleId === 3 ||
                          profile?.roleId === 5 ||
                          profile?.roleId === 13) && (
                          <SwapHoriz data-qa="HeaderFirmArrows" className={classes.firmSwitch} fontSize="large" />
                        )}
                      </IconButton>
                    ) : null}
                  </>
                ) : null}
                <Popper
                  className={classes.popper}
                  placement="bottom-end"
                  anchorEl={firmAnchorEl}
                  open={isFirmMenuOpen}
                  ref={popperRef}
                >
                  <div className={classes.popperContent}>
                    <Autocomplete
                      options={firmOptions}
                      getOptionLabel={(option) => option.name}
                      open
                      data-qa="HeaderFirmNameSearch"
                      onClose={handleMenuClose}
                      classes={{
                        option: classes.popperOption,
                        popperDisablePortal: classes.popperDisablePortal,
                        paper: classes.popperPaper,
                        listbox: classes.popperlistbox,
                      }}
                      onChange={(event, newValue) => {
                        //firmChange logic
                        handleFirmChange(newValue?.value);
                      }}
                      disableCloseOnSelect
                      disablePortal
                      renderTags={() => null}
                      noOptionsText="No labels"
                      value={firm}
                      renderInput={(params) => (
                        <div className={classes.inputBaseWrap}>
                          <TextField
                            variant="outlined"
                            label="Select Firm"
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            className={classes.inputBase}
                            InputLabelProps={{ shrink: true }}
                            onClick={() => console.log('ok')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="select firm"
                                    onClick={() => {
                                      setFirm({ name: '', value: '' });
                                    }}
                                    edge="end"
                                  >
                                    <Clear fontSize="large" />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </Popper>
                {currentView !== 'public' && (
                  <Button
                    aria-label="account of current user"
                    aria-controls={'account-menu'}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    variant="text"
                    size="large"
                    className={classes.accountButton}
                    data-qa="HeaderUserAccount"
                  >
                    <div className={classes.accountButtonTxt}>
                      <Typography variant="h2">{name ? nameTag() : 'U'} </Typography>
                    </div>
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            getContentAnchorEl={null}
            id={'account-menu'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{ className: classes.accountMenuWrap }}
            MenuListProps={{ className: classes.accountMenuList }}
          >
            <div className={classes.accountMenu}>
              <MenuItem className={classes.accountMenuDetails} disableRipple>
                <Typography variant="h3">{name ? name : ''}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {email}
                </Typography>
              </MenuItem>
              {!isStaffAdmin && (
                <>
                  <MenuItem className={clsx(classes.disableHover, classes.divider)}>
                    <AgentNetButton
                      variant="outlined"
                      color="primary"
                      disableFocusRipple
                      disableRipple
                      data-qa="HeaderManageProfile"
                      onClick={onViewSwitch}
                    >
                      {'Manage User Profile'}
                    </AgentNetButton>
                  </MenuItem>
                  <MenuItem
                    onClick={() => setContactUsModal(true)}
                    className={classes.disableHover}
                    data-qa="HeaderContactUs"
                  >
                    <MessageOutlined fontSize="large" color="primary" />
                    <Typography variant="body1" className={classes.menuIconBtn}>
                      Contact Us
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleHelpClick} className={classes.disableHover} data-qa="HeaderHelp">
                    <HelpOutlineOutlined fontSize="large" color="primary" />
                    <Typography variant="body1" className={classes.menuIconBtn}>
                      Help
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => setAboutModal(true)}
                    className={classes.disableHover}
                    data-qa="HeaderAboutApp"
                  >
                    <InfoOutlined fontSize="large" color="primary" />
                    <Typography variant="body1" className={classes.menuIconBtn}>
                      About App
                    </Typography>
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={handleSwitchToClassicClick}
                className={classes.disableHover}
                data-qa="HeaderSwitchToClassic"
              >
                <ExitToAppOutlined fontSize="large" color="primary" />
                <Typography variant="body1" className={classes.menuIconBtn}>
                  Switch to Classic
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout} className={classes.disableHover} disableRipple data-qa="HeaderSignOut">
                <LogoutOutlined />
                <Typography variant="body1" className={classes.menuIconBtn}>
                  Sign out
                </Typography>
              </MenuItem>
            </div>
          </Menu>
          {!hideNav && !isStaffAdmin && !isProfileLoading && (
            <TopNav activeTab={activeTab} onTabChange={handleTabChange} tabs={topNavTabs}>
              {noBehalfOfUser || currentView === 'admin' ? null : (
                <div className={classes.createNewFileBtn}>
                  <AgentNetButton
                    color="primary"
                    size="medium"
                    variant="contained"
                    data-qa="GlobalNavCreateNewFile"
                    disabled={false}
                    onClick={() => {
                      if (behalfOfUser && !userFirm?.firmId) {
                        //setDisplayError(true);
                        return;
                      }
                      // If the Edit File form has unsaved changes, open the modal
                      if (isFormDirty) {
                        setOpenSaveModal && setOpenSaveModal(true);
                        setIsCreateDrawerClicked && setIsCreateDrawerClicked(true);
                      }
                      // otherwise, just open the drawer
                      else {
                        setOpenCreateFileDrawer(true);
                        setFirmAnchorEl && setFirmAnchorEl(null);
                      }
                    }}
                    fullWidth
                    plusIcon
                  >
                    Create New File
                  </AgentNetButton>
                </div>
              )}
            </TopNav>
          )}
          {isFSC && !isProfileLoading && (
            <TopNav activeTab={activeTab} onTabChange={handleTabChange} tabs={topOrderManagementNavTabs}>
              {noBehalfOfUser || currentView === 'admin' ? null : (
                <div className={classes.createNewFileBtn}>
                  <AgentNetButton
                    color="primary"
                    size="medium"
                    variant="contained"
                    data-qa="GlobalNavCreateNewOrder"
                    disabled={false}
                    onClick={() => {
                      if (behalfOfUser && !userFirm?.firmId) {
                        //setDisplayError(true);
                        return;
                      }
                      // If the Edit File form has unsaved changes, open the modal
                      if (isFormDirty) {
                        setOpenSaveModal && setOpenSaveModal(true);
                        setIsCreateDrawerClicked && setIsCreateDrawerClicked(true);
                      }
                      // otherwise, just open the drawer
                      else {
                        setOpenCreateFileDrawer(true);
                        setFirmAnchorEl && setFirmAnchorEl(null);
                      }
                    }}
                    fullWidth
                    plusIcon
                  >
                    {activeTab == 1 ? 'Create New Order' : 'Create New File'}
                  </AgentNetButton>
                </div>
              )}
            </TopNav>
          )}
        </div>
      )}
      <AboutAppDialog open={aboutModal} onClose={() => setAboutModal(false)} />
      <ContactUsDialog open={contactUsModal} onClose={() => setContactUsModal(false)} />
      {isFSC && activeTab == 1 ? (
        <OrderCreateProvider>
          <OrderCreateContainer {...{ openDrawer: openCreateFileDrawer, setOpenDrawer: setOpenCreateFileDrawer }} />
        </OrderCreateProvider>
      ) : (
        <FileCreateProvider>
          <FileCreateContainer {...{ openDrawer: openCreateFileDrawer, setOpenDrawer: setOpenCreateFileDrawer }} />
        </FileCreateProvider>
      )}
    </>
  );
};

export default GlobalHeader;
